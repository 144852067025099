<template>
    <div>
        Login Page
    </div>
</template>

<script>
export default {
    name:'Login'
}
</script>